<script>
import { mapGetters, mapActions } from "vuex";
import AppProgress from "@/features/general/Progress";

export default {
  name: "AppCustomerPreview",
  components: {
    AppProgress,
  },
  data() {
    return {
      otherFields: ["rg", "gender"],
      companyFields: ["cnpj", "name", "state_inscription"],
      customerFields: [
        "birth_date",
        "cpf",
        "mobile_phone",
        "email",
        "name",
        "phone",
      ],
    };
  },
  props: {
    /**
     * Lista as informações do usuário
     */
    list: {
      type: Object,
      default() {
        return {
          customer: {
            birth_date: "",
            cpf: "",
            company: {
              cnpj: "",
              name: "",
              state_inscription: "",
            },
            email: "",
            id: "",
            mobile_phone: "",
            name: "",
            phone: "",
            type: "",
            profile: {
              name: "",
            },
          },
          store: {
            id: "",
            uri: {
              https: "",
            },
            settings: {
              store: {
                change_profile: "",
              },
            },
          },
        };
      },
    },
    loading: Boolean,
  },

  watch: {
    list() {
      this.calculateProgress();
    },
  },

  methods: {
    ...mapActions("Customer", ["setVuelidateErrors"]),

    /**
     * Calcula o progresso do cadastro validando se os campos estão preenchidos
     * @return {undefined}
     */
    calculateProgress() {
      this.customerFields.map((field) => {
        if (this.isFieldFilled(this.list.store.customer, field)) {
          if (field === "mobile_phone") {
            field = "cellphone";
          }

          this.setVuelidateErrors({ [field]: false });
        }
      });

      this.companyFields.map((field) => {
        if (this.isFieldFilled(this.list.store.customer.company, field)) {
          if (field === "name") {
            field = "company_name";
          }

          this.setVuelidateErrors({ [field]: false });
        }
      });

      this.otherFields.map((field) => {
        this.setVuelidateErrors({ [field]: false });
      });
    },

    /**
     * Valida se o campo está preenchido
     * @param {object} path caminho até o campo
     * @param {string} field campo
     * @return {bool}
     */
    isFieldFilled(path, field) {
      if (path[field]) {
        return path[field].length > 0;
      }
    },
  },

  computed: {
    ...mapGetters(["langs"]),
    ...mapGetters("Customer", ["customerProgress"]),

    /**
     * Retorna o link de alteração de dados da central antiga
     * @return {string}
     */
    editDataLink() {
      return `${this.list.store.uri.https}/loja/central_dados.php?loja=${this.list.store.id}`;
    },

    /**
     * Verifica se o usuario cadastrado é uma empresa
     * @returns {Boolean} sim caso seja false caso contrário
     */
    isCompany() {
      return this.list.store.customer.type === "pj";
    },

    /**
     * Retorna o título para a progresso do cadastro
     * @return {string}
     */
    customerProgressTitle() {
      return this.customerProgress < 100
        ? this.langs.profile["almost-there"]
        : this.langs.profile.congrats;
    },

    /**
     * Retorna a label para a progresso do cadastro
     * @return {string}
     */
    customerProgressLabel() {
      return this.customerProgress < 100
        ? this.langs.profile.complete_personal_info
        : this.langs.profile.registration_complete;
    },
  },
};
</script>

<template>
  <section class="app__customer-preview">
    <header class="app__customer-preview__header">
      <h2
        class="app__dashboard__title app__dashboard__title--2"
        :class="{ app__loading: loading }"
      >
        {{ this.langs.profile["personal-data"] }}
      </h2>
    </header>
    <article class="app__customer-preview__content">
      <div class="row">
        <div class="col-md-9">
          <div class="row app__customer-preview__info-wrap">
            <div class="col-12 col-md-6">
              <!-- @TODO Um componente para o customer-preview-info ? -->
              <div class="row">
                <div class="col-8 col-md-12">
                  <div class="app__customer-preview__info">
                    <strong
                      class="app__customer-preview__label app__customer-preview__label-name"
                      :class="{ app__loading: loading }"
                    >
                      {{ this.langs.profile["full-name"] }}
                    </strong>
                    <span
                      class="app__customer-preview__info app__customer-preview__info-name"
                      :class="{ app__loading: loading }"
                    >
                      {{ this.list.store.customer.name }}
                    </span>
                  </div>

                  <div class="app__customer-preview__info">
                    <strong
                      class="app__customer-preview__label app__customer-preview__label-email"
                      :class="{ app__loading: loading }"
                    >
                      E-mail
                    </strong>
                    <span
                      class="app__customer-preview__info app__customer-preview__info-email"
                      :class="{ app__loading: loading }"
                    >
                      {{ list.store.customer.email }}
                    </span>
                  </div>

                  <div class="app__customer-preview__info">
                    <strong
                      class="app__customer-preview__label app__customer-preview__label-cpf"
                      :class="{ app__loading: loading }"
                    >
                      CPF
                    </strong>
                    <span
                      class="app__customer-preview__info app__customer-preview__info-cpf"
                      :class="{ app__loading: loading }"
                    >
                      {{ list.store.customer.cpf || "-" }}
                    </span>
                  </div>

                  <div v-if="isCompany">
                    <div class="app__customer-preview__info">
                      <strong
                        class="app__customer-preview__label app__customer-preview__label-birth-date"
                        :class="{ app__loading: loading }"
                      >
                        {{ this.langs.profile["birthday"] }}
                      </strong>
                      <span
                        class="app__customer-preview__info app__customer-preview__info-birth-date"
                        :class="{ app__loading: loading }"
                      >
                        {{ list.store.customer.birth_date || "-" }}
                      </span>
                    </div>

                    <div class="app__customer-preview__info">
                      <strong
                        class="app__customer-preview__label app__customer-preview__label-phone"
                        :class="{ app__loading: loading }"
                      >
                        {{ this.langs.profile["home-phone"] }}
                      </strong>
                      <span
                        class="app__customer-preview__info app__customer-preview__info-phone"
                        :class="{ app__loading: loading }"
                      >
                        {{ list.store.customer.phone || "-" }}
                      </span>
                    </div>

                    <div class="app__customer-preview__info">
                      <strong
                        class="app__customer-preview__label app__customer-preview__label-mobile-phone"
                        :class="{ app__loading: loading }"
                      >
                        {{ this.langs.profile["cellphone"] }}
                      </strong>
                      <span
                        class="app__customer-preview__info app__customer-preview__info-mobile-phone"
                        :class="{ app__loading: loading }"
                      >
                        {{
                          list.store.customer.mobile_phone
                            ? list.store.customer.mobile_phone
                            : "-"
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-4 d-block d-md-none app__progress__content">
                  <app-progress
                    :loading="loading"
                    :width="80"
                    :progress="loading ? 1 : this.customerProgress"
                    :title="this.customerProgressTitle"
                    :label="this.customerProgressLabel"
                    type="circle"
                  >
                  </app-progress>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div v-if="isCompany || loading">
                <div class="app__customer-preview__info">
                  <hr class="app__separator d-md-none" />
                  <strong
                    class="app__customer-preview__label app__customer-preview__label-company-name"
                    :class="{ app__loading: loading }"
                  >
                    {{ this.langs.profile["company-name"] }}
                  </strong>
                  <span
                    class="app__customer-preview__info app__customer-preview__info-company-name"
                    :class="{ app__loading: loading }"
                  >
                    {{ list.store.customer.company.name || "-" }}
                  </span>
                </div>

                <div class="app__customer-preview__info">
                  <strong
                    class="app__customer-preview__label app__customer-preview__label-cnpj"
                    :class="{ app__loading: loading }"
                  >
                    CNPJ
                  </strong>
                  <span
                    class="app__customer-preview__info app__customer-preview__info-cnpj"
                    :class="{ app__loading: loading }"
                  >
                    {{ list.store.customer.company.cnpj || "-" }}
                  </span>
                </div>

                <div class="app__customer-preview__info">
                  <strong
                    class="app__customer-preview__label app__customer-preview__label-state-inscription"
                    :class="{ app__loading: loading }"
                  >
                    {{ this.langs.profile["state-inscription"] }}
                  </strong>
                  <span
                    class="app__customer-preview__info app__customer-preview__info-state-inscription"
                    :class="{ app__loading: loading }"
                  >
                    {{ list.store.customer.company.state_inscription || "-" }}
                  </span>
                </div>
              </div>
              <div v-else>
                <div class="app__customer-preview__info">
                  <strong
                    class="app__customer-preview__label app__customer-preview__label-birth-date"
                    :class="{ app__loading: loading }"
                  >
                    {{ this.langs.profile["birthday"] }}
                  </strong>
                  <span
                    class="app__customer-preview__info app__customer-preview__info-birth-date"
                    :class="{ app__loading: loading }"
                  >
                    {{ list.store.customer.birth_date || "-" }}
                  </span>
                </div>

                <div class="app__customer-preview__info">
                  <strong
                    class="app__customer-preview__label app__customer-preview__label-phone"
                    :class="{ app__loading: loading }"
                  >
                    {{ this.langs.profile["home-phone"] }}
                  </strong>
                  <span
                    class="app__customer-preview__info app__customer-preview__info-phone"
                    :class="{ app__loading: loading }"
                  >
                    {{ list.store.customer.phone || "-" }}
                  </span>
                </div>

                <div class="app__customer-preview__info">
                  <strong
                    class="app__customer-preview__label app__customer-preview__label-mobile-phone"
                    :class="{ app__loading: loading }"
                  >
                    {{ this.langs.profile["cellphone"] }}
                  </strong>
                  <span
                    class="app__customer-preview__info app__customer-preview__info-mobile-phone"
                    :class="{ app__loading: loading }"
                  >
                    {{
                      list.store.customer.mobile_phone
                        ? list.store.customer.mobile_phone
                        : "-"
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 d-none d-md-block app__progress__content">
          <app-progress
            :loading="loading"
            :width="80"
            :progress="loading ? 1 : this.customerProgress"
            :title="this.customerProgressTitle"
            :label="this.customerProgressLabel"
            type="circle"
          >
          </app-progress>
        </div>
      </div>

      <section class="app__customer-preview__actions">
        <router-link
          class="app__button app__button--primary app__button--no-margin"
          :class="{ app__loading: loading }"
          tag="a"
          :to="{ name: 'profile.main' }"
        >
          {{ this.langs.profile["edit-personal-data"] }}
        </router-link>
      </section>
    </article>
  </section>
</template>
