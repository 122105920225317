<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AppOrderStatus from "../Orders/components/status";
import AppOrderAddress from "../Orders/components/address";
import AppOrderNotFound from "../Orders/components/not-found";
import AppCustomerPreview from "../Customer/components/preview";

export default {
  /**
   * Para evitar conflitos com elementos HTML padrão,
   * utilize o prefixo App antes do nome do componente
   */
  name: "AppDashboard",

  components: {
    AppCustomerPreview,
    AppOrderStatus,
    AppOrderAddress,
    AppOrderNotFound,
  },

  /**
   * Faz a requisição assim que o componente é montado e quando há storeId no vuex
   */
  watch: {
    hasStoreId: {
      immediate: true,
      handler(storeId) {
        if (this.hasToken && storeId) {
          this.fetch();
        }
      },
    },
  },

  methods: {
    ...mapActions("Orders", [
      "setOrderData",
      "setOrderTimeline",
      "setOrderLoading",
      "setMainLoading",
    ]),

    ...mapActions(["setFirstAccess"]),

    async fetch() {
      try {
        this.setOrderLoading({ loading: true });
        const { hash } = await this.getLastOrder();
        const { hasPendingReview } = await this.isPendingReview(hash);

        if (hasPendingReview && !this.isFirstAccess) {
          this.setFirstAccess({ isFirstAccess: true });
          this.$router.push({ path: `/orders/${hash}` });
          return;
        }
        const lastOrderComplete = await this.getOrderComplete(hash);
        await this.setOrderData({
          // Junta os dados da request com o que já existe na lista
          list: { ...this.list, ...lastOrderComplete },
        });
        this.setMainLoading(false);
        this.setOrderLoading({ loading: false });
      } catch (error) {
        if (error.status === 404) {
          this.setMainLoading(false);
          this.setOrderLoading({ loading: false });
        }

        throw error;
      }
    },

    /**
     * Recupera o ultimo pedido da loja
     * @return {object}
     */
    async getLastOrder() {
      const { data: lastOrder } = await this.$http.get("/orders?limit=1");
      const retrieveAndRemoveFirstIndex = lastOrder.data.shift();
      return retrieveAndRemoveFirstIndex;
    },

    /**
     * Confere se existe uma avaliação pendente de um pedido
     * @param {string} hash Hash representando a identificação do pedido
     * @return {Promise}
     */
    async isPendingReview(hash) {
      const { data } = await this.$http.get(`/has-pending-review/${hash}`);
      return data;
    },

    /**
     * Recupera o pedido completo
     * @param {string} hash Hash representando a identificação do pedido
     * @return {object}
     */
    async getOrderComplete(hash) {
      const orderComplete = await this.$http
        .get(`/orders/${hash}`)
        .then((response) => response.data);
      return orderComplete;
    },

    /**
     * Retorna os dados do usuario
     */
    previewList() {
      const list = {
        customer: this.customerInfo,
        store: this.storeInfo,
      };
      return list;
    },

    /**
     * @returns {string} ID do pedido
     */
    orderId() {
      return this.list.id;
    },

    /**
     * @returns {string} HASH do pedido
     */
    orderHash() {
      return this.list.hash;
    },
  },
  /**
   * mapState irá trazer as propriedades do estado (Vuex) para o escopo
   * deste componente
   */
  computed: {
    ...mapGetters(["langs"]),
    ...mapGetters("Customer", ["customerInfo"]),
    ...mapState({
      storeInfo: (state) => state.store_info.list,
      isFirstAccess: (state) => state.isFirstAccess,
    }),
    ...mapState("Orders", {
      list: (state) => state.order.list,
      timeline: (state) => state.order.timeline,
      loading: (state) => state.loading,
      orderLoading: (state) => state.order.loading,
    }),

    /**
     * Verifica se há storeId no vuex
     * @return {bool}
     */
    hasStoreId() {
      return this.storeInfo.id;
    },

    /**
     * Valida se há token salvo no localStorage
     * @return {bool}
     */
    hasToken() {
      const jwtToken = localStorage.getItem("jwtToken");
      return jwtToken && jwtToken != "false";
    },
  },
};
</script>

<template>
  <section class="app__dashboard">
    <header class="app__dashboard__header">
      <h2
        class="app__dashboard__title app__dashboard__title--2"
        :class="{ app__loading: orderLoading }"
      >
        {{ this.langs.summary["last-order-data"] }}
      </h2>
    </header>
    <article class="app__dashboard__order" v-if="orderId() || loading">
      <app-order-status />
      <app-order-address />
      <section class="app__dashboard__actions">
        <router-link
          class="app__button app__button--primary app__button--no-margin"
          tag="a"
          :title="orderId()"
          :to="{ name: 'orders.detail', params: { id: orderHash() || 1 } }"
          :class="{ app__loading: orderLoading }"
        >
          {{ this.langs.summary["order-details"] }}
        </router-link>
      </section>
    </article>
    <article class="app__dashboard__order" v-else>
      <app-order-not-found
        :list="previewList()"
        :loading="loading"
      ></app-order-not-found>
    </article>
    <section class="app__dashboard__customer">
      <app-customer-preview
        :list="previewList()"
        :loading="loading"
      ></app-customer-preview>
    </section>
  </section>
</template>
